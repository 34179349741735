import {Controller} from "stimulus"

export default class extends Controller {
    static values = {targetId: String}

    copy() {
        let target = document.getElementById(this.targetIdValue)
        if (target) {
            this.newWayToCopy(target.innerHTML)
        }
    }

    // this works on Chrome
    oldWayToCopy(str) {
        function listener(e) {
            e.clipboardData.setData("text/html", str);
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        }

        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
    };

    // this works on Safari and is supposed to be the new way to write to the clipboard
    // so, we'll try it and catch any exceptions.  If we except, we'll try the old way.
    newWayToCopy(str) {
        const text = 'text/plain'
        const html = 'text/html'
        let textBlob = new Blob([str], {text});
        let htmlBlob = new Blob([str], {html});

        try {
            navigator.clipboard.write([
                new ClipboardItem({
                    "text/plain": Promise.resolve(textBlob),
                    "text/html": Promise.resolve(htmlBlob),
                }),
            ])
        }
        catch(err) {
            this.oldWayToCopy(str)
        }
    }
}

