import {Controller} from "stimulus"
import Inputmask from 'inputmask'
import Cookies from 'js-cookie'

// used to make sure the date is valid on the encounter form
export default class extends Controller {
    static values = {
        validCss: String,
        invalidCss: String,
        notificationTarget: String,
        notificationEvent: String,
    }

    connect() {
        this.element.classList.add('border', 'border-gray-200')
        Inputmask({
                "mask": "99\-99\-9999",
                "oncomplete": this.setState.bind(this),
            }
        ).mask(this.element)

        this.setState(this.element)
    }

    disconnect() {
        Inputmask.remove(this.element)
    }

    // look up the event name and target element and then dispatch
    // called anytime we change the state of this date element
    dispatchEvent() {
        let event = new Event(this.notificationEventValue)
        let targets = this.notificationTargetValue

        if (event !== null && targets !== null) {
            for (let id of targets.split(' ')) {
                let el = document.getElementById(id)
                if (el !== null) {
                    el.dispatchEvent(event)
                }
            }
        }
    }

    // fired when the field is a full date string (see format above)
    // but all we know is we have a bunch of number, not that they are rational
    // note: this is bound at the call site
    complete(el) {
        // sometimes el is the element from setState, sometimes it's a customEvent from inputmask
        if (el.type === "complete") {
            el = el.target
        }
        let val = el.inputmask.unmaskedvalue()
        let month = val.substring(0, 2) - 1
        let day = val.substring(2, 4)
        let year = val.substring(4, 8)
        let today = new Date()
        let input = new Date(year, month, day)

        if (month < 0 || month >= 12) {
            this.setInvalid(el)
            return
        }

        const listOfDays = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (day < 1 || day > listOfDays[month]) {
            this.setInvalid(el)
            return
        }

        if (year < 1900 || year > today.getFullYear()) {
            this.setInvalid(el)
            return
        }

        if (input > today) {
            this.setInvalid(el)
            return
        }

        // we get here, we're complete AND valid
        this.setValid(el)
        Cookies.set("dob", this.element.value, {sameSite: 'strict'})
    }

    focusChange(event) {
        this.setState(event.currentTarget)
    }

    // sets the CSS to show an invalid state
    setInvalid(el) {
        if (this.validCssValue !== "") {
            el.classList.remove(...this.validCssValue.split(' '))
        }

        if (this.invalidCssValue !== "") {
            el.classList.add(...this.invalidCssValue.split(' '))
        }
    }

    // sets the CSS to show a valid state
    setValid(el) {
        if (this.invalidCssValue !== "") {
            el.classList.remove(...this.invalidCssValue.split(' '))
        }

        if (this.validCssValue !== "") {
            el.classList.add(...this.validCssValue.split(' '))
        }
    }

    // figures out what start we're in and calls the helper to handle the heavy lifting
    setState(el) {
        if (el.type === 'cleared' || el.type === 'incomplete' || el.type === 'complete') {
            el = el.currentTarget
        }

        let len = el.inputmask.unmaskedvalue().length

        if (len === 8) {
            this.complete(el) // complete handles setting valid/invalid
            Cookies.set("dob", el.value, {sameSite: 'strict'})
        } else if (len > 0) {
            this.setInvalid(el)
        } else {
            // len === 0
            this.setValid(el)
        }

        this.dispatchEvent()
    }
}
