import {Controller} from "stimulus"
import Cookies from "js-cookie"

// Simple show/hide toggler for the deleted section of the library
// If the button is in the show state (button will display 'hide'),
// then we also need to unhide the entire 'category_deleted' tree
export default class extends Controller {
    toggle(event) {
        // toggle button state
        if (event.currentTarget.textContent.trim() === 'Show') {
            event.currentTarget.textContent = 'Hide'
            Cookies.set('discarded', 'show', { sameSite: 'strict' })
        } else {
            event.currentTarget.textContent = 'Show'
            Cookies.set('discarded', 'hide', { sameSite: 'strict' })
        }

        // make the display consistent with button state
        document.getElementById("category_deleted").classList.toggle("hidden")
    }
}
