import {Controller} from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs";

export default class extends Controller {
    connect() {
        // setup the DnD for the encounter_videos
        this.sortable = Sortable.create(document.getElementById('encounter-playlist'), {
            handle: '.handle',
            animation: 150,
            onEnd: this.end.bind(this)
        })
    }

    // sends an update to rails with the playlist to add to the encounter
    playlistAdd(event) {
        let data = new FormData()
        data.append("playlist_id", event.currentTarget.dataset.encounterPlaylistId)

        Rails.ajax({
            url: this.data.get("url"),
            type: 'POST',
            data: data
        })
    }

    libraryAdd(event) {
        let data = new FormData()
        data.append("library_id", event.currentTarget.dataset.encounterLibraryId)

        Rails.ajax({
            url: this.data.get("url"),
            type: 'POST',
            data: data
        })
    }

    trash(event) {
        let encounterVideoId = event.currentTarget.dataset.encounterVideoId

        Rails.ajax({
            url: this.data.get("url") + this.data.get("itemUrl").replace(":id", encounterVideoId),
            type: 'DELETE',
        })
    }

    end(event) {
        let encounterVideoId = event.item.dataset.encounterVideoId
        let data = new FormData()
        data.append("position", event.newIndex + 1)

        Rails.ajax({
            url: this.data.get("url") + this.data.get("itemUrl").replace(":id", encounterVideoId),
            type: 'PATCH',
            data: data
        })
    }
}
