import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    delete(event) {
        let playlistId = this.data.get("id")
        let id = event.currentTarget.dataset.id

        // tell rails we don't want this one anymore
        Rails.ajax({
            url: this.data.get("url").replace(":playlist_id", playlistId).replace(":id", id),
            type: 'DELETE'
        })

        // remove the hidden class for this library item now that it's removed from the playlist
        // and remove the node from the playlist
        let libraryId = event.currentTarget.dataset.libraryId
        let playlistLibraryId = event.currentTarget.dataset.playlistLibraryId
        document.getElementById("library_" + libraryId).classList.remove("hidden")
        document.getElementById("playlist_library_" + playlistLibraryId).remove()
    }

    create(event) {
        let playlistId = this.data.get("id")
        let id = event.currentTarget.dataset.playlistLibraryId

        let data = new FormData()
        data.append("library_id", id)

        // tell rails we don't want this one anymore
        Rails.ajax({
            url: this.data.get("url").replace(":playlist_id", playlistId).replace(":id", id),
            type: 'POST',
            data: data
        })

        // add the hidden class for this library item now that it's added to the playlist
        let libraryId = event.currentTarget.dataset.playlistLibraryId
        document.getElementById("library_" + libraryId).classList.add("hidden")
    }
}
