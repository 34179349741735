// supports sorting the playlist  library items on playlist/show

import {Controller} from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
    connect() {
        let id = this.data.get("category-id")
        this.sortable = new Sortable(this.element, {
            group: {
                name: "playlist-category-" + id,
                pull: false,
            },
            handle: '.handle',
            animation: 150,
            onEnd: this.end.bind(this),
        });
    }

    end(event) {
        let playlistId = this.data.get("id")
        let id = event.item.dataset.playlistLibraryId

        let data = new FormData()
        data.append("position", event.newIndex + 1)

        Rails.ajax({
            url: this.data.get("url").replace(":playlist_id", playlistId).replace(":id", id),
            type: 'PATCH',
            data: data
        })
    }
}
