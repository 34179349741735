import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    delete(event) {
        event.stopPropagation()

        let id = event.currentTarget.dataset.id
        let playlistId = event.currentTarget.dataset.playlistId

        // remove the playlist_libraries from the playlist
        Rails.ajax({
            url: this.data.get("url").replace(":playlist_id", playlistId).replace(":id", id),
            type: 'DELETE'
        })

        //
        // now clean up what the user sees
        //

        // remove the deleted node
        event.currentTarget.closest("LI").remove()

        // let's get the UL node so we can do a count of li's
        let controller = this.element
        if (controller.getElementsByTagName("LI").length === 0) {
            // if there are no more LI elements, then we should remove the instructions div
            controller.remove()
        }
    }
}
