import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["filename", "name"]

    // copies the source file name into the database name field
    changed() {
        if (this.filenameTarget.files[0].name != "") {
            this.nameTarget.value = this.filenameTarget.files[0].name.split(".", 1)
        }
    }
}
