import { Controller } from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
    recoverStart(e) {
        e.currentTarget.classList.add("hidden")

        let encounterGroup = e.currentTarget.closest(".encounter-group")
        let recoverName = encounterGroup.getElementsByClassName("recover-name")[0]
        let input = recoverName.getElementsByTagName("input")[0]
        encounterGroup.classList.add("border-red-500")
        recoverName.classList.remove("hidden")
        input.focus()
    }

    input(e) {
        let fakeButton = e.currentTarget.parentElement.getElementsByClassName("fake-button")[0]

        if (e.currentTarget.value.length > 0) {
            fakeButton.classList.remove("bg-blue-300", "pointer-events-none")
            fakeButton.classList.add("bg-blue-500", "hover:bg-blue-500")
        } else {
            fakeButton.classList.remove("bg-blue-500", "hover:bg-blue-500")
            fakeButton.classList.add("bg-blue-300", "pointer-events-none")
        }
        Cookies.set("patient", e.currentTarget.value, { sameSite: 'strict' })
    }
}
