import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['nextButton']

    atEnded(_event) {
        let nextButton = this.nextButtonTarget

        // If the next button doesn't have pointer-events-none, then it's visible
        // and we have a button we can submit on
        if (!nextButton.classList.contains("pointer-events-none")) {
            setTimeout(() => {
                nextButton.parentElement.submit()
            }, 10000)
        }
    }
}
