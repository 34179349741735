import {Controller} from "stimulus";
import Rails from "@rails/ujs"

// this controller does all of the JS required for the search function
// * Dynamically searches
//  * For now, this operations 'like' a form, but it's here so we can do key by key searches.
//    But, we need a way to handle key debounce so we don't search multiple times while other
//    searches are still inflight.
// * Manages what displays on the screen -- show / hide stuff
// * Does pushState stuff so once can share URLs and refresh the screen an end up in the same place
export default class extends Controller {
    // triggers the actual search
    search(event) {
        let searchTerm = event.currentTarget.value.trim().toLowerCase()
        let url = event.currentTarget.dataset.videoSearchUrl
        let urlWithSearch = url + "?term=" + searchTerm

        // we don't search on less than 3 characters
        // FIXME: temporarily turned off and this needs to be put back
        if (false && event.currentTarget.value.length < 3) {
            this.clearSearchResults()
            history.pushState(null, "Search", url)
            return
        }


        Rails.ajax({
                url: urlWithSearch,
                type: 'GET',
            },
        )

        // make sure browser refresh works
        history.pushState(null, "Search", urlWithSearch)

        // since we're searching again, we should clear any flash messages
        document.getElementById("flash").innerHTML = ""
    }

    // click handler to add a searched result into the users library
    addToLibrary(event) {
        let li = event.currentTarget.closest("LI")
        let id = li.dataset.id
        let url = document.getElementById("searched_videos").dataset.videoSearchUrl

        let data = new FormData()
        data.append("id", id)

        Rails.ajax({
            url: url,
            type: 'POST',
            data: data,
        })
    }

    clearSearchResults() {
        let sv = document.getElementById("searched_videos")
        let nr = document.getElementById("no_results")

        // remove previous search results if any
        sv.classList.add("hidden")
        sv.innerHTML = ""

        // remove no results message if any
        nr.classList.add("hidden")
    }
}
