import {Controller} from "stimulus"

export default class extends Controller {
    static values = {
        errorCss: String,
        errorTest: String,
        notificationEvent: String,
        checkIds: String
    }

    connect() {
        this.element.addEventListener(this.notificationEventValue, this.checkState.bind(this))
        this.checkState()
    }

    disconnet() {
        this.element.removeEventListener(this.notificationEventValue, this.checkState)
    }

    // checkState figures out what to look for and respond accordingly
    checkState(event) {
        let err = false

        // for each id
        for(let id of this.checkIdsValue.split(" ")) {
            let el = document.getElementById(id)
            if (el !== null && document.getElementById(id).classList.contains(this.errorTestValue) === true) {
                err = true
            }
        }

        if (err) {
            this.element.classList.add(...this.errorCssValue.split(' '))
            this.element.disabled = true
        } else {
            this.element.classList.remove(...this.errorCssValue.split(' '))
            this.element.disabled = false
        }
    }
}
