import {Controller} from "stimulus"
import Cookies from "js-cookie"

// This stimulus controller sets a cookie that we use to control
// to configure Timezone support on a per request basis.
//
// Typical use: attached to the outer most element in the layouts
export default class extends Controller {
    connect() {
        Cookies.set(
            "browser.timezone",
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            { sameSite: 'strict' }
        );
    }
}
