import {Controller} from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
    static targets = ["menu", "close", "sidebar", "ariaExpand", "sidebarText", "sidebarWidth"]

    // closes the profile menu with a click anywhere on the screen
    // other that the menu itself (which is handled in toggleMenu()
    close() {
        // it seems we hit a new and undocumented feature.  Targets
        // don't include nested controller scope.  I'm putting this here
        // until we know for sure this behavior is unavoidable
        let el = null
        if (this.hasCloseTarget == true) {
            el = this.closeTarget
        } else {
            el = document.getElementById("closeTarget")
        }

        if (el && el.getAttribute("aria-expanded") == "true") {
            this.toggleHidden(el)
            this.toggleAriaExpanded(el)
        }
    };

    // toggle the visibility of the profile menu
    toggleMenu() {
        this.toggleHidden(this.menuTarget)
        this.toggleAriaExpanded(this.menuTarget)
    }

    // toggles full open vs. compressed on pages with a sidebar
    toggleSidebar() {
        for (let e of this.sidebarTargets) {
            this.toggleHidden(e)
        }

        for (let e of this.sidebarTextTargets) {
            this.toggleHidden(e)
        }

        this.toggleAriaExpanded(this.ariaExpandTarget)

        let el = this.sidebarWidthTarget
        if (el.classList.contains("w-48")) {
            el.classList.remove("w-48")
            el.classList.add("w-16")
            Cookies.set("sidebarMenu", "closed", { sameSite: 'strict' })
            Turbolinks.clearCache()
        } else {
            el.classList.remove("w-16")
            el.classList.add("w-48")
            Cookies.set("sidebarMenu", "open", { sameSite: 'strict' })
            Turbolinks.clearCache()
        }
    }

    // sets aria-expanded to true/false
    toggleAriaExpanded(el) {
        if (el.getAttribute("aria-expanded") == "true") {
            el.setAttribute("aria-expanded", "false")
        } else {
            el.setAttribute("aria-expanded", "true")
        }
    }

    // adds/removes hidden class
    toggleHidden(el) {
        if (el.classList.contains("hidden")) {
            el.classList.remove("hidden")
        } else {
            el.classList.add("hidden")
        }
    };

    // special handling for the mobile profile menu
    // because it's structure is more spread out
    mobile() {
        for (let e of this.menuTargets) {
            if (e.classList.contains("block")) {
                e.classList.remove("block")
                e.classList.add("hidden")
            } else {
                e.classList.remove("hidden")
                e.classList.add("block")
            }
        }

        let mel = document.getElementById("mobile-menu")
        if (mel.classList.contains("hidden")) {
            mel.classList.remove("hidden")
        } else {
            mel.classList.add("hidden")
        }
    };
}
