import {Controller} from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
    static values = {
        validCss: String,
        invalidCss: String,
        notificationTarget: String,
        notificationEvent: String
    }

    connect() {
        this.input(this)
    }

    // sets the invalid state
    setInvalid() {
        if (this.validCssValue !== "") {
            this.element.classList.remove(...this.validCssValue.split(' '))
        }

        if (this.invalidCssValue !== "") {
            this.element.classList.add(...this.invalidCssValue.split(' '))
        }
    }

    // sets the valid state
    setValid() {
        if (this.invalidCssValue !== "") {
            this.element.classList.remove(...this.invalidCssValue.split(' '))
        }

        if (this.validCssValue !== "") {
            this.element.classList.add(...this.validCssValue.split(' '))
        }

        Cookies.set("patient", this.element.value, {sameSite: 'strict'})
    }

    // on input, check for our condition and then toggle the valid state appropriately
    input(_e) {
        if (this.element.value.length > 0) {
            this.setValid()
        } else {
            this.setInvalid()
        }

        this.notify()
    }

    // tells our target to pay attention, something has changed
    notify() {
        if (this.notificationTargetValue !== null && this.notificationTargetValue !== '') {
            for (let id of this.notificationTargetValue.split(' ')) {
                let el = document.getElementById(id)
                if (el !== null) {
                    let event = new Event(this.notificationEventValue)
                    el.dispatchEvent(event)
                }
            }
        }
    }
}
