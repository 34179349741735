import {Controller} from "stimulus"
import Cookies from "js-cookie"
import Rails from "@rails/ujs"

export default class extends Controller {
    // sends the selected doc from the pull down to rails for this encounter
    connect() {
        this.revealStartButton()
        this.element.addEventListener("revealStartButton", this.revealStartButton)
    }

    disconnect() {
        this.element.removeEventListener("revealStartButton", this.revealStartButton)
    }

    select_doc(event) {
        // send the doc to the app
        let doc = event.target.options[event.currentTarget.selectedIndex]
        let id = this.data.get("id")
        let data = new FormData()
        data.append("user_id", doc.value)

        Rails.ajax({
            url: this.data.get("url").replace(":id", id),
            type: 'PATCH',
            data: data
        })

        Cookies.set("doctor_id", doc.value, { sameSite: 'strict' })
    }

    // called whenever a input text field is set
    setCookie(event) {
        let name = event.target.id
        let value = event.target.value

        Cookies.set(name, value, { sameSite: 'strict' })
        this.revealStartButton()
    }

    // show/hide the start encounter button
    revealStartButton() {
        let button = document.getElementById("start-button")
        let encounterPlaylist = document.getElementById("encounter-playlist")
        let patient = document.getElementById('patient')
        let dob = document.getElementById('dob')

        let patientReveal = false
        let dobReveal = false
        let hasVideoReveal = false

        if (patient.value.length > 0) {
            patientReveal = true
        }

        if (!dob.classList.contains('border-red-500')) {
            dobReveal = true
        }

        if (encounterPlaylist !== null && encounterPlaylist.childElementCount > 0) {
            hasVideoReveal = true
        }

        if (patientReveal && dobReveal && hasVideoReveal) {
            button.removeAttribute("disabled")
            button.removeAttribute("class")
            let css = document.getElementById("start-button-enabled").getAttribute("class")
            button.setAttribute("class", css)
            button.classList.remove("hidden")
        } else {
            if (button != null) {
                button.setAttribute("disabled", "disabled")
                button.removeAttribute("class")
                let css = document.getElementById("start-button-disabled").getAttribute("class")
                button.setAttribute("class", css)
                button.classList.remove("hidden")
            }
        }
    }
}
