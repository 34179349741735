import {Controller} from "stimulus"
import {AwsS3Multipart, Core, Dashboard, FileInput, Informer, StatusBar} from "uppy";
import Rails from "@rails/ujs"

export default class extends Controller {
    connect() {
        // const formGroup = this.element.parentNode
        const url = this.data.get("url")

        const uppy = Core({
            // logger: Core.debugLogger,
            autoProceed: true,
            restrictions: {
                allowedFileTypes: ['.mp4', '.m4v', '.mov', '.wmv']
            }
        }).use(Dashboard, {
            target: "#drag-drop-area",
            width: 750,
            height: 350,
            thumbnailWidth: 280,
            showProgressDetails: true,
            proudlyDisplayPoweredByUppy: false,
            replaceTargetContent: true,
        }).use(AwsS3Multipart, {
            companionUrl: '/',
        })

        this.uppy = uppy

        // s3 multipart implementation
        // Fires for each successful file upload
        uppy.on('upload-success', (file, response) => {
            var uploadedFileData = JSON.stringify({
                id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
                storage: 'cache',
                metadata: {
                    size: file.size,
                    filename: file.name,
                    mime_type: file.type,
                }
            })

            // Tell rails about each file as it's successfully loaded
            let data = new FormData()
            data.append("json", uploadedFileData)

            Rails.ajax({
                url: url,
                type: 'POST',
                data: data,
                error: function (data) {
                    uppy.info(data.messages, "error", 100000)
                }
            })
        })
    }

    openModal(e) {
        let db = this.uppy.getPlugin("Dashboard")
        if (!db.isModalOpen()) {
            db.openModal()
        }
    }
}
