import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static values = {
        url: String,
        id: String
    }

    update() {
        Rails.ajax({
            url: this.urlValue.replace(":id", this.idValue),
            type: "PATCH",
            success: () => {
                window.print()
            }
        })
    }
}
