import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["modal"]

    // this gets fired when clicking anywhere on the open modal.  Be aware
    openModal(e) {
        let modal = e.currentTarget.nextElementSibling // the modal is right next to this button

        // if the modal for this video is currently hidden, we need to open it ip and play it
        if (modal.classList.contains("hidden")) {
            e.preventDefault();
            e.stopPropagation()
            modal.blur();
            modal.classList.remove("hidden");
            modal.classList.add("modal-open"); // we add this to make it easier to close
            modal.getElementsByTagName("VIDEO")[0].play()
        }
    }

    closeModal(e) {
        e.preventDefault()
        e.stopPropagation()

        // we get the event on the close button OF THE MODAL, so we have to look up for the video tag
        let video = e.currentTarget.previousElementSibling
        if (video) {
            video.pause()
        }

        // now we have to find the first node of the modal itself
        let modal = e.currentTarget.closest(".modal-open")
        modal.classList.add("hidden")
        modal.classList.remove("modal-open")
    }

    closeWithKeyboard(e) {
        let modal = document.getElementsByClassName("modal-open")[0]
        if (modal && e.keyCode === 27 && !modal.classList.contains("hidden")) {
            let video = modal.getElementsByTagName("VIDEO")[0]
            if (video) {
                video.pause()
            }
            modal.classList.add("hidden")
            modal.classList.remove("modal-open")
        }
    }
}
