import {Controller} from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

export default class extends Controller {
    connect() {
        this.sortable = new Sortable(this.element, {
            group: {
                name: 'shared',
                put: this.canPut.bind(this),
            },
            handle: '.handle',
            filter: '.divider',
            sort: this.canSort(this),
            animation: 150,
            onEnd: this.end.bind(this),
            onStart: this.onStart.bind(this),
        })

        this.togglePadding(this.element)
    }

    canSort(that) {
        if (that.element.id === "category_uncategorized" || that.element.id === "category_deleted") {
            return false
        }

        return true
    }

    canPut(to) {
        return !to.el.classList.contains("NoDrop")
    }

    // we compare each LI element for a duplicate with the event.item.
    // If we find a dup (by name), we set the .opacity-25 on the UL element
    // to make the entire drop region look like it won't accept a drop
    onStart(event) {
        let sourceUL = event.item.closest("UL")
        let allULs = document.getElementById("grid").getElementsByTagName("UL")

        let name = event.item.getElementsByClassName("library-name")[0].textContent.trim().toLowerCase()

        for (let ul of allULs) {
            if (ul === sourceUL) {
                continue // don't look for a dup name in our source category
            }

            let possibleNames = ul.getElementsByClassName("library-name")
            for (let n of possibleNames) {
                if (n.textContent.trim().toLowerCase() === name) {
                    ul.classList.add("opacity-25", "NoDrop")
                }
            }
        }
    }

    // Function to remove the .opacity-25, where ever it might be
    removeOpacity() {
        let allULs = document.getElementById("grid").getElementsByTagName("UL")

        for (let ul of allULs) {
            ul.classList.remove("opacity-25", "NoDrop")
        }
    }

    end(event) {
        this.removeOpacity() // remove the css classes if they have been applied

        if (event.to.classList.contains("NoDrop")) {
            return
        }

        let id = event.item.dataset.id
        let data = new FormData()
        // FIXME: why / how did this change and does it impact all the other sorts
        // data.append("position", event.newIndex + 1) // the + 1 used to be required, now not so much?
        data.append("position", event.newIndex)
        data.append("category_id", event.to.dataset.category_id)

        Rails.ajax({
            url: this.data.get("url").replace(":id", id),
            type: 'PATCH',
            data: data
        })

        this.togglePadding(event.from)
        this.togglePadding(event.to)
    }

    togglePadding(el) {
        if (el.getElementsByTagName("li").length == 0) {
            el.classList.add("empty-libraries-item-padding")
        } else {
            el.classList.remove("empty-libraries-item-padding")
        }
    }
}
