import {Controller} from "stimulus"
import Rails from "@rails/ujs"

// This controller prevents scrubbing forward until/unless the video has been played at least once
// It also notifies the App when the video has been successfully played at least once
export default class extends Controller {
    connect() {
        this.demoMode = false
        this.hasCompleted = false
        this.currentTime = parseInt(this.data.get("currentTime"))
        this.timeRemaining = parseInt(this.data.get("timeRemaining"))

        this.questionsChecked()

        if (this.data.get("hasCompleted") === "true") {
            this.hasCompleted = true
            this.activeNextStepsButton()
        }

        if (!this.hasCompleted && this.currentTime > 0) {
            // for uncompleted videos, we try to remember where we left off
            // we need this meshuggeneh because it's javascript and each browser
            // seems to want to be a snowflake
            let video = document.getElementById('video-player')
            let that = this
            video.addEventListener("loadeddata", function _loadedDataListener(){
                video.currentTime = that.currentTime
                video.removeEventListener("loadeddata", _loadedDataListener, true)
            })
        }

        if (this.data.get("demoMode") === "true") {
            this.demoMode = true
        }
    }

    // capture timeUpdate events unless we hasCompleted
    timeUpdate(event) {
        let video = event.currentTarget

        // only record things
        if (!video.seeking && video.currentTime > this.currentTime) {
            this.currentTime = video.currentTime

            let remaining = Math.trunc(this.timeRemaining - video.currentTime)
            let remainder = remaining % 60

            let tre = document.getElementById("time-remaining")
            tre.innerText = ""
            if (remainder <= 9) {
                tre.innerText = `${Math.trunc(remaining / 60)}:0${remainder}`
            } else {
                tre.innerText = `${Math.trunc(remaining / 60)}:${remainder}`
            }
        }
    }

    // manage seeking events: only back unless hasCompleted
    seeking(event) {
        if (this.hasCompleted || this.demoMode) {
            return
        }

        let video = event.currentTarget
        let delta = video.currentTime - this.currentTime

        if (delta > 0.01) {
            video.currentTime = this.currentTime
        }
    }

    // We've reached the end of the video, so
    // notify Rails that this video has been successfully watched at least once
    // also, do a number of user friendly changes to the screen:
    //  * if the radio buttons have been checked / selected, switch in the next video button
    //  * update the number of videos watched so it count is accurate and not confusing if we
    //      end up sitting on this screen for any amount of time
    videoAtEnd(event) {
        let encounterId = this.data.get("encounterId")
        let id = this.data.get("id")
        let data = new FormData()
        data.append("completed", "true")
        data.append("current_time", event.currentTarget.currentTime)

        Rails.ajax({
            url: this.data.get("url").replace(":encounter_id", encounterId).replace(":id", id),
            type: 'PATCH',
            data: data,
        })

        this.hasCompleted = true
        this.activeNextStepsButton()
    }

    // updates the encounter_video.current_time so if we bounce around,
    // we restart where we left off
    // We only do this if this video is in an uncompleted state
    updateCurrentTime(event) {
        if (this.hasCompleted) {
            return
        }

        let encounterId = this.data.get("encounterId")
        let id = this.data.get("id")
        let data = new FormData()
        data.append("current_time", document.getElementById('video-player').currentTime)

        Rails.ajax({
            url: this.data.get("url").replace(":encounter_id", encounterId).replace(":id", id),
            type: 'PATCH',
            data: data,
        })
    }

    // tell the server that we've clicked the radio buttons
    checkClicked(event) {
        let encounterId = this.data.get("encounterId")
        let id = this.data.get("id")
        let data = new FormData()
        let questions = this.questionsChecked()
        data.append("has_questions", `${questions}`)

        Rails.ajax({
            url: this.data.get("url").replace(":encounter_id", encounterId).replace(":id", id),
            type: 'PATCH',
            data: data,
        })
    }

    activeNextStepsButton() {
        let button = document.getElementById("next-steps-button")
        if (button === null) {
            return // this is ok -- sometimes this button doesn't exist
        }

        button.classList.remove("pointer-events-none", "bg-gray-400")
        button.classList.add("bg-green-400", "hover:bg-green-600")
    }

    // returns if we've checked one of the 'I have questions' checkbox
    // FIXME: this needs to be broken into more functions.  The current
    //  function is destructive and does more than the function name suggests
    questionsChecked() {
        let button = document.getElementById("next-steps-button")

        if (button === null) {
            return // this is ok, sometimes this button doesn't exist
        }

        let checked = document.getElementById("questions").checked

        if (checked) {
            button.setAttribute("data-confirm-question", button.getAttribute("data-confirm"))
            button.removeAttribute("data-confirm")
        } else {
            if (button.getAttribute("data-confirm-question") !== null) {
                button.setAttribute("data-confirm", button.getAttribute("data-confirm-question"))
                button.removeAttribute("data-confirm-question")
            }
        }
        return checked
    }
}
